const {
  REACT_APP_PROD_API_KEY,
  REACT_APP_PROD_AUTH_DOMAIN,
  REACT_APP_PROD_PROJECT_ID,
  REACT_APP_PROD_STORAGE_BUCKET,
  REACT_APP_PROD_MESSAGING_SENDER_ID,
  REACT_APP_PROD_DATABASE_URL,
} = process.env;

export default {
  apiKey: REACT_APP_PROD_API_KEY,
  authDomain: REACT_APP_PROD_AUTH_DOMAIN,
  databaseURL: REACT_APP_PROD_DATABASE_URL,
  projectId: REACT_APP_PROD_PROJECT_ID,
  storageBucket: REACT_APP_PROD_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_PROD_MESSAGING_SENDER_ID,
};
