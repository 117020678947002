import { React, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Typography, Space, message } from "antd";
import "./index.less";
import { getLocation } from "../../../helpers/validation/validateLocation";
import { addFocusOutEventListeners } from "../../../helpers/iosscroll";
import STATUS from "../../../constants/STATUS";

const { Title } = Typography;

const ManualLocationInput = ({ onSubmit }) => {
  const [form] = Form.useForm();

  useEffect(() => addFocusOutEventListeners(), [form]);

  const handleSubmit = async ({ location }) => {
    const result = await onSubmit(location);
    if (!result || !result.status) {
      message.error(
        "Manual Location Input did not return a valid Verification Result.",
      );
      return;
    }
    switch (result.status) {
      case STATUS.success:
        form.resetFields();
        break;
      case STATUS.error:
        form.setFields([
          {
            name: "location",
            errors: [
              result.message
                ? result.message
                : "Could not validate submitted location.",
            ],
          },
        ]);
        break;
      default:
        break;
    }
  };

  return (
    <div className="manual-location-input-container">
      <Title className="help-message" level={4}>
        Location Tag (Manual Entry)
      </Title>
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name="location"
          validateTrigger="onBlur"
          rules={[
            { required: true, message: "Please enter a location." },
            {
              validator: (_, value) => {
                if (!value || getLocation(value)) return Promise.resolve();
                return Promise.reject(
                  new Error(`${value} is not a valid location.`),
                );
              },
            },
          ]}>
          <Space className="manual-location-input-space" direction="vertical">
            <Input placeholder="Custom Work Bench #1" />
          </Space>
        </Form.Item>
        <Form.Item>
          <Button
            className="manual-location-input-button"
            type="primary"
            htmlType="submit">
            Set Location
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

ManualLocationInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ManualLocationInput;
